import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import FindBrokerHero from 'components/FindBroker/Hero';
import FindBrokerBody from 'components/FindBroker/Body';

const FindABroker: React.FC = () => {
  return (
    <Layout>
      <SEO title="Find a Mortgage Broker in Ireland" />
      <FindBrokerHero />
      <FindBrokerBody />
    </Layout>
  );
};

export default FindABroker;
